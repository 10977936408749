<template lang="pug">
.code-insert-layout(:class="contentPadding")
  code-insert-header(
    v-if="showHeader"
    :has-check="showCheckButton"
    :has-connect-later="showSkip"
    :has-finish="showFinish"
    :has-next="showNext"
    @action="headerEvents"
    :status="status"
    :page-id="pageId"
  )
  code-insert-notifications(:status="status" :platform="platform")
  router-view(v-bind="childProps")
</template>

<script>
  import { CodeInsertHeader, CodeInsertNotifications } from '@/components/CodeInsert';
  import headerMixin from '@/mixins/codeinsert/header';

  export default {
    name: 'InsertCodeLayout',
    components: {
      CodeInsertNotifications,
      CodeInsertHeader,
    },
    mixins: [headerMixin],
    data: () => ({
      status: null,
    }),
    computed: {
      childProps() {
        const { domain = null, campaign = null, step = null } = this.$route.query;
        const { platform = null } = this.$route.params;
        return { domain, platform, campaign, step };
      },
      contentPadding() {
        return { 'px-5': this.isOnboarding };
      },
    },
  };
</script>
<style lang="sass">
  .code-insert-layout
    container: card/inline-size
</style>
